he-text-area::part(base), he-text-field::part(base), he-select::part(base){
    border: 1px solid var(--he-color-neutral-200);
}

he-filter-pill {
    margin-right: 10px;    
}

he-filter-pill::part(button) {
    background-color: var(--he-color-primary-100);
    border-radius: 12px;
}

he-breadcrumb {
    position: fixed;
    background: white;
    z-index: 1;
    width: 100%;
}

.blue-loader-rect {
    border-radius: 4px;
    width: 215px;
    height: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
    --shimmer-color: var(--he-color-primary-100);
    --color: var(--he-color-primary-300);
}

he-message-bar {
    position: fixed;
    right: 10px;
    z-index: 1000;
    top: 60px;
    width: 300px;
}