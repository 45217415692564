.wrapper,
.wrapper-collapsed {
  justify-content: center;
  width: 1210px;
  margin: 0 auto;
  padding-top: 40px;

}

.upload-file,
.upload-file-collapsed {
  width: 100%;
  background-color: rgb(240, 240, 240);
  padding: 40px;
  margin-bottom: 10px;
}

.heading {
  font-weight: 100;
  margin-bottom: 20px;
}

.button {
  margin-right: 20px;
  margin-top: 20px;
  width: 150px;
  height: 40px;
  font-size: 14px;
  border: none;
}

.input-field,
.input-field-collapsed {
  display: flex;
  margin-top: 10px;
}


.input-text,
.input-text-collapsed {
  display: inline-block;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.input-text input[type="text"],
.input-text-collapsed input[type="text"] {
  width: 320px;
  height: 35px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}


.input-file {
  display: inline-block;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-button {
  background-color: #006cc2;
  color: white;
  padding: 7px 35px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  height: 35px;
  box-sizing: border-box;

}

.custom-button:hover {
  background-color: #0078d7;
}

.lead-enrichment-success {
  background-color: #dff0d8;
  color: #3c763d;
  border: 1px solid #d6e9c6;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
}

.lead-enrichment-error {
  background-color: #f2dede;
  color: #a94442;
  border: 1px solid #ebccd1;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.lead-enrichment-close-icon {
  cursor: pointer;

}

.lead-enrichment-close-icon:hover {
  color: #000;
}

@media screen and (max-width: 1600px) {
  .wrapper-collapsed {
    width: 1000px;
  }
}

@media screen and (max-width: 1290px) {
  .wrapper {
    width: 1050px;
  }
}

@media screen and (max-width: 1400px) {
  .wrapper-collapsed {
    width: 800px;
  }
}

@media screen and (max-width: 1120px) {
  .wrapper {
    width: 850px;
  }
}

@media screen and (max-width: 1150px) {
  .wrapper-collapsed {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
}

@media screen and (max-width: 930px) {
  .wrapper {
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
  }
}

@media screen and (max-width: 706px) {
  .wrapper {
    padding: 10px;
  }

}

@media screen and (max-width: 653px) {
  .wrapper-collapsed {
    width: 320px;
  }
}

@media screen and (max-width: 416px) {
  .wrapper {
    width: 320px;
  }
}

@media screen and (max-width: 826px) {
  .input-field-collapsed {
    flex-direction: column;
  }
}

@media screen and (max-width: 666px) {
  .input-field {
    flex-direction: column;
  }

}

@media screen and (max-width: 714px) {
  .input-text-collapsed input[type="text"] {
    width: 250px;
  }

}

@media screen and (max-width: 576px) {
  .input-text input[type="text"] {
    width: 250px;
  }

}