.domain-mapping-wrapper,
.domain-mapping-wrapper-collapsed {
    justify-content: center;
    padding-top: 40px;
    width: 1200px;
    margin: 0 auto;
}

.domain-mapping-header,
.domain-mapping-header-collapsed {
    width: 100%;
    background-color: rgb(240, 240, 240);
    padding: 40px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.domain-mapping-heading {
    font-weight: 100;
}

.domain-mapping-select-country,
.domain-mapping-select-country-collapsed {
    display: flex;
    align-items: center;
}

.domain-mapping-authorized-countries,
.domain-mapping-authorized-countries-collapsed {
    width: 283px;
    margin-left: 20px;
    font-size: 16px;
    height: 35px;

}

.domain-mapping-authorized-countries-menu {
    width: 283px;
}

.domain-mapping-manual,
.domain-mapping-manual-collapsed {
    display: flex;
    margin-top: 20px;
}

.domain-mapping-create_btn,
.domain-mapping-create_btn-collapsed {
    margin-left: 20px;
    height: 35px;
    width: 200px;
    font-size: 14px;
    border: none;
}

.domain-mapping-form,
.domain-mapping-form-collapsed {
    display: table;
    width: 100%;
    padding-left: 120px;
}

.domain-mapping-input-fields div,
.domain-mapping-input-fields-collapsed div {
    display: table-row;
}

.domain-mapping-input-fields div label,
.domain-mapping-input-fields-collapsed div label {
    display: table-cell;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;
}

.domain-mapping-input-fields div div,
.domain-mapping-input-fields-collapsed div div {
    display: table-cell;
}

.domain-mapping-input-fields div input,
.domain-mapping-input-fields-collapsed div input {
    display: table-cell;
    width: 500px;
    height: 35px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    box-sizing: border-box;

}

.domain-mapping-input-fields div div div input,
.domain-mapping-input-fields-collapsed div div div input {
    display: table-cell;
    width: 465px;
    height: 35px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    box-sizing: border-box;

}

.view-suggested-companies-checkbox,
.view-suggested-companies-checkbox-collapsed {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 175px;
    margin-bottom: 10px;
}

.view-suggested-companies-checkbox input[type="checkbox"],
.view-suggested-companies-checkbox-collapsed input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.view-suggested-companies-checkbox label,
.view-suggested-companies-checkbox-collapsed label {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 100;
}

.certify-domain-checkbox,
.certify-domain-checkbox-collapsed {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 150px;
    margin-bottom: 10px;
}

.certify-domain-checkbox input[type="checkbox"],
.certify-domain-checkbox-collapsed input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.certify-domain-checkbox label,
.certify-domain-checkbox-collapsed label {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 100;
}


.domain-mapping-func_btn {
    margin-right: 20px;
    margin-top: 20px;
    height: 35px;
    font-size: 14px;
    border: none;
}

.domain-mapping-btn-footer,
.domain-mapping-btn-footer-collapsed {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 170px;
}

.domain-mapping-success {
    background-color: #dff0d8;
    color: #3c763d;
    border: 1px solid #d6e9c6;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
}

.domain-mapping-error {
    background-color: #f2dede;
    color: #a94442;
    border: 1px solid #ebccd1;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
}

.domain-mapping-close-icon {
    cursor: pointer;

}

.domain-mapping-close-icon:hover {
    color: #000;
}


.btn-footer {
    margin: 0 auto;
}

.menu {
    max-height: 200px;
    max-width: 500px;
    overflow-y: auto;
    white-space: nowrap;
}

.menu-item {
    white-space: normal;
    margin-right: 10px;
}

.marginLeftForDomainName,
.marginLeftForDomainName-collapsed {
    margin-left: 17px;
}

.marginLeftForSuggestedCompaniesCheckbox,
.marginLeftForSuggestedCompaniesCheckbox-collapsed {
    margin-left: 17px;
}

.marginLeftForSearchPane,
.marginLeftForSearchPane-collapsed {
    margin-left: 25px;
}

.marginLeftForCrmId,
.marginLeftForCrmId-collapsed {
    margin-left: 25px;
}

.marginLeftForTpId,
.marginLeftForTpId-collapsed {
    margin-left: 25px;
}

.marginLeftForCompanyName,
.marginLeftForCompanyName-collapsed {
    margin-left: 25px;
}

.marginLeftForCertifyDomainCheckbox40px,
.marginLeftForCertifyDomainCheckbox40px-collapsed {
    margin-left: 40px;
}

.marginLeftForCertifyDomainCheckbox25px,
.marginLeftForCertifyDomainCheckbox25px-collapsed {
    margin-left: 25px;
}

.nomargin {
    margin-left: 0px;
}

@media screen and (max-width: 1580px) {
    .domain-mapping-wrapper-collapsed {
        width: 1000px;
    }
}

@media screen and (max-width: 1300px) {
    .domain-mapping-wrapper {
        width: 1000px;
    }
}

@media screen and (max-width: 1380px) {
    .domain-mapping-wrapper-collapsed {
        width: 950px;
    }
}


@media screen and (max-width: 1100px) {
    .domain-mapping-wrapper {
        width: 950px;
    }

}

@media screen and (max-width: 1320px) {
    .domain-mapping-wrapper-collapsed {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }
}

@media screen and (max-width: 1040px) {
    .domain-mapping-wrapper {
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media screen and (max-width: 675px) {
    .domain-mapping-wrapper-collapsed {
        width: 350px;
    }
}

@media screen and (max-width: 460px) {
    .domain-mapping-wrapper {
        width: 350px;
    }
}

@media screen and (max-width: 932px) {
    .domain-mapping-select-country-collapsed {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .domain-mapping-authorized-countries-collapsed {
        margin-left: 0px;
    }
}

@media screen and (max-width: 714px) {
    .domain-mapping-select-country {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .domain-mapping-authorized-countries {
        margin-left: 0px;
    }
}

@media screen and (max-width: 1162px) {
    .domain-mapping-manual-collapsed {
        flex-direction: column;
        gap: 20px;
    }

    .domain-mapping-create_btn-collapsed {
        margin-left: 0px;
    }
}

@media screen and (max-width: 970px) {
    .domain-mapping-manual {
        flex-direction: column;
        gap: 20px;
    }

    .domain-mapping-create_btn {
        margin-left: 0px;
    }

}

@media screen and (max-width: 1300px) {
    .domain-mapping-form-collapsed {
        padding-left: 60px;
    }
}

@media screen and (max-width: 1000px) {
    .domain-mapping-form {
        padding-left: 60px;
    }
}

@media screen and (max-width: 1172px) {
    .domain-mapping-form-collapsed {
        display: block;
        padding-left: 0px;
    }

    .domain-mapping-input-fields-collapsed div {
        display: block;
        margin-bottom: 20px;
    }

    .domain-mapping-input-fields-collapsed div label {
        display: block;
        margin-bottom: 5px;
        padding: 0px;
    }

    .domain-mapping-input-fields-collapsed div input[type="text"] {
        display: block;
        width: 100%;
        margin-left: 0px;
    }

    .domain-mapping-input-fields-collapsed div div {
        display: block;
        width: 100%;

    }

    .domain-mapping-btn-footer-collapsed {
        margin-left: 0px;
    }

    .certify-domain-checkbox-collapsed {
        margin-left: 0px;
    }

    .view-suggested-companies-checkbox-collapsed {
        margin-left: 0px;
    }

    .marginLeftForCertifyDomainCheckbox25px-collapsed {
        margin-left: 0px;
    }

    .marginLeftForCertifyDomainCheckbox40px-collapsed {
        margin-left: 0px;
    }

    .marginLeftForCompanyName-collapsed {
        margin-left: 0px;
    }

    .marginLeftForTpId-collapsed {
        margin-left: 0px;
    }

    .marginLeftForCrmId-collapsed {
        margin-left: 0px;
    }

    .marginLeftForSearchPane-collapsed {
        margin-left: 0px;
    }

    .marginLeftForSuggestedCompaniesCheckbox-collapsed {
        margin-left: 0px;
    }

    .marginLeftForDomainName-collapsed {
        margin-left: 0px;
    }


}

@media screen and (max-width: 910px) {
    .domain-mapping-form {
        display: block;
        padding-left: 0px;
    }

    .domain-mapping-input-fields div {
        display: block;
        margin-bottom: 20px;
    }

    .domain-mapping-input-fields div label {
        display: block;
        margin-bottom: 5px;
        padding: 0;
    }

    .domain-mapping-input-fields div input[type="text"] {
        display: block;
        width: 100%;
        margin-left: 0px;
    }

    .domain-mapping-input-fields div div {
        display: block;
        width: 100%;
    }

    .domain-mapping-btn-footer {
        margin-left: 0px;
    }

    .certify-domain-checkbox {
        margin-left: 0px;
    }

    .view-suggested-companies-checkbox {
        margin-left: 0px;
    }

    .menu {
        width: 100%
    }

    .marginLeftForCertifyDomainCheckbox25px {
        margin-left: 0px;
    }

    .marginLeftForCertifyDomainCheckbox40px {
        margin-left: 0px;
    }

    .marginLeftForCompanyName {
        margin-left: 0px;
    }

    .marginLeftForTpId {
        margin-left: 0px;
    }

    .marginLeftForCrmId {
        margin-left: 0px;
    }

    .marginLeftForSearchPane {
        margin-left: 0px;
    }

    .marginLeftForSuggestedCompaniesCheckbox {
        margin-left: 0px;
    }

    .marginLeftForDomainName {
        margin-left: 0px;
    }

}

@media screen and (max-width: 520px) {
    .domain-mapping-btn-footer {
        flex-direction: column;
        width: 150px;
    }

}