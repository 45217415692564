
.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}



.crmStatus, .crmStatus-collapsed{
    margin-right: 10px;
    width: 150px;
}

.certifiedStatus, .certifiedStatus-collapsed{
    margin-right: 10px;
    width: 150px;
}

@media screen and (max-width: 810px){
    .datagrid-search-box{
        width: 200px;
    }
}

@media screen and (max-width: 999px){
    .datagrid-filter-bar-collapsed{
        flex-direction: column;
        gap: 10px;
    }
    .crmStatus-collapsed{
        width: 200px;
        margin-right: 0px;
    }
    .certifiedStatus-collapsed{
        width: 200px;
        margin-right: 0px;
    }
}

@media  screen and (max-width: 768px){
    .datagrid-filter-bar{
        
        flex-direction: column;
        /* justify-content: flex-start; */
        align-items: flex-start;
        margin-left: 0px;
        justify-content: flex-start;
        gap: 10px;


        
    }
    .crmStatus{
        width: 200px;
        margin-right: 0px;
    }
    .certifiedStatus{
        width: 200px;
        margin-right: 0px;
    }
}

@media screen and (max-width: 700px){
    .datagrid-bar-collapsed{
        flex-direction: column;
        gap: 10px;
    }
}
    
@media screen and (max-width: 470px){
    .datagrid-bar{
        flex-direction: column;
        gap: 10px;
    }
}