.nav-panel {
    width: 280px;
    background-color: var(--he-color-neutral-200);
    position: fixed;
    height: 100vh;
    z-index: 1000;
}

.nav-panel.collapsed {
    width: 50px;
}

.nav-item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    cursor: pointer;
}

.nav-item:hover {
    background-color: var(--he-color-neutral-300);    
}

.nav-item > div:first-child {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.nav-item he-icon {
    font-size: 20px;
}

.nav-item span {
    margin-left: 10px;
}

.nav-item.selected {
    border-left: 3px solid var(--he-color-primary-500);
    background-color: var(--he-color-neutral-300);
}

.nav-menu-icon {
    margin-left: 7px;
    margin-top: 10px;
}

.nav-menu-icon he-icon {
    font-size: 20px;
    cursor: pointer;
}

@media screen and (max-height: 345px){
    .nav-panel {
        height: 345px;
    }
}
    
