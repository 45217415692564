.company-mapping-wrapper,
.company-mapping-wrapper-collapsed {
    justify-content: center;
    padding-top: 40px;
    width: 1200px;
    margin: 0 auto;
}

.company-mapping-header,
.company-mapping-header-collapsed {

    width: 100%;
    background-color: rgb(240, 240, 240);
    padding: 40px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.company-mapping-heading {
    font-weight: 100;
}

.company-mapping-authorized-countries,
.company-mapping-authorized-countries-collapsed {
    width: 283px;
    margin-left: 20px;
    height: 35px;
    font-size: 16px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.company-mapping-create_btn,
.company-mapping-create_btn-collapsed {
    margin-left: 20px;
    height: 35px;
    width: 200px;
    font-size: 14px;
    border: none;
}

.company-mapping-select-country,
.company-mapping-select-country-collapsed {
    display: flex;
    align-items: center;
}

.company-mapping-manual,
.company-mapping-manual-collapsed {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}


.authorized-countries {
    width: 250px;
    margin-left: 20px;
    margin-right: 20px;
}

.company-mapping-authorized-countries-menu {
    width: 283px;
}

.company-mapping-form,
.company-mapping-form-collapsed {
    display: table;
    width: 100%;
    padding-left: 60px;
}

.company-mapping-input-fields div,
.company-mapping-input-fields-collapsed div {
    display: table-row;
}

.company-mapping-input-fields div label,
.company-mapping-input-fields-collapsed div label {
    display: table-cell;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;

}

.company-mapping-input-fields div input,
.company-mapping-input-fields-collapsed div input {
    display: table-cell;
    width: 500px;
    height: 35px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.company-mapping-input-fields div div,
.company-mapping-input-fields-collapsed div div {
    display: table-cell;
    align-items: center;
}

.company-mapping-checkbox {
    display: flex;
    align-items: center;
    margin-top: 10px;
    /* margin-left: 300px; */
    margin-bottom: 20px;
}

.company-mapping-checkbox input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.company-mapping-checkbox label {
    margin-left: 5px;
    font-size: 20px;
    font-weight: 100;
}

.includecrmid-checkbox-collapsed {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 210px;
    margin-bottom: 10px;
}

.includecrmid-checkbox-collapsed input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.includecrmid-checkbox-collapsed label {
    margin-left: 5px;
    font-size: 16px;
    font-weight: 100;
}

.company-mapping-func_btn-collapsed {
    margin-right: 20px;
    margin-top: 20px;
    height: 35px;
    font-size: 14px;
    border: none;

}



.includecrmid-checkbox {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 210px;
    margin-bottom: 10px;
}

.includecrmid-checkbox input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.includecrmid-checkbox label {
    margin-left: 5px;
    font-size: 16px;
    font-weight: 100;
}

.company-mapping-func_btn {
    margin-right: 20px;
    margin-top: 20px;
    height: 35px;
    font-size: 14px;
    border: none;

}

.company-mapping-success {
    background-color: #dff0d8;
    color: #3c763d;
    border: 1px solid #d6e9c6;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
}

.company-mapping-error {
    background-color: #f2dede;
    color: #a94442;
    border: 1px solid #ebccd1;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
}

.company-mapping-close-icon {
    cursor: pointer;

}

.company-mapping-close-icon:hover {
    color: #000;
}

.company-mapping-btn-footer-collapsed {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 210px;
}

.company-mapping-btn-footer {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 230px;
}

@media screen and (max-width: 1600px) {
    .company-mapping-wrapper-collapsed {
        width: 1000px;
    }
}

@media screen and (max-width: 1300px) {
    .company-mapping-wrapper {
        width: 1000px;
    }
}

@media screen and (max-width: 1400px) {
    .company-mapping-wrapper-collapsed {
        width: 950px;
    }

}

@media screen and (max-width: 1100px) {
    .company-mapping-wrapper {
        width: 950px;
    }

}

@media screen and (max-width: 1340px) {
    .company-mapping-wrapper-collapsed {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }
}

@media screen and (max-width: 1040px) {
    .company-mapping-wrapper {
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media screen and (max-width: 710px) {
    .company-mapping-wrapper-collapsed {
        width: 350px;
    }
}

@media screen and (max-width: 460px) {
    .company-mapping-wrapper {
        width: 350px;

    }
}

@media screen and (max-width: 1166px) {
    .company-mapping-manual-collapsed {
        flex-direction: column;
        gap: 20px;
    }

    .company-mapping-create_btn-collapsed {
        margin-left: 0px;
    }

}

@media screen and (max-width: 962px) {
    .company-mapping-select-country-collapsed {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .company-mapping-authorized-countries-collapsed {
        margin-left: 0px;
    }
}

@media screen and (max-width: 714px) {
    .company-mapping-select-country {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .company-mapping-authorized-countries {
        margin-left: 0px;
    }
}

@media screen and (max-width: 970px) {
    .company-mapping-manual {
        flex-direction: column;
        gap: 20px;
    }

    .company-mapping-create_btn {
        margin-left: 0px;
    }
}




@media screen and (max-width: 1210px) {
    .company-mapping-form-collapsed {
        display: block;
        padding-left: 0px;
    }

    .company-mapping-input-fields-collapsed div {
        display: block;
        margin-bottom: 20px;
        width: 100%;
    }

    .company-mapping-input-fields-collapsed div label {
        display: block;
        margin-bottom: 5px;
        padding: 0;
    }

    .company-mapping-input-fields-collapsed div input[type="text"] {
        display: block;
        width: 100%;
        font-size: 16px;
        border: 1px solid #ccc;
        box-sizing: border-box;
    }

    .company-mapping-input-fields-collapsed div div {
        width: 100%;
        display: block;
    }

    .includecrmid-checkbox-collapsed {
        margin-left: 0px;
    }

    .company-mapping-btn-footer-collapsed {
        margin-left: 0px;
    }
}

@media screen and (max-width: 910px) {
    .company-mapping-form {
        display: block;
        padding-left: 0px;
    }

    .company-mapping-input-fields div {
        display: block;
        margin-bottom: 20px;
        width: 100%;
    }

    .company-mapping-input-fields div label {
        display: block;
        margin-bottom: 5px;
        padding: 0;
    }

    .company-mapping-input-fields div input[type="text"] {
        display: block;
        width: 100%;
        font-size: 16px;
        border: 1px solid #ccc;
        box-sizing: border-box;
    }

    .company-mapping-input-fields div div {
        width: 100%;
        display: block;
    }

    .includecrmid-checkbox {
        margin-left: 0px;
    }

    .company-mapping-btn-footer {
        margin-left: 0px;
    }
}

@media screen and (max-width: 640px) {
    .company-mapping-btn-footer-collapsed {
        flex-direction: column;
        width: 175px;
    }
}

@media screen and (max-width: 490px) {
    .company-mapping-btn-footer {
        flex-direction: column;
        width: 175px;
    }
}