.app-body {
    display: flex;
    margin-top: 48px;
    height: calc(100vh - 48px);
}

.root-container {
    padding: 0 20px 20px;
    width: calc(100vw - 280px);
    margin-left: 280px;
    position: static;
    /* overflow: auto; */
}

.root-container.collapsed {
    margin-left: 50px;
    width: calc(100vw - 50px);
}
