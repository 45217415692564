.jobtitle-wrapper,
.jobtitle-wrapper-collapsed {
    justify-content: center;
    padding-top: 40px;
    width: 1100px;
    margin: 0 auto;
}

.jobtitle-create-mapping,
.jobtitle-create-mapping-collapsed {
    width: 100%;
    background-color: rgb(240, 240, 240);
    padding: 40px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.jobtitle-heading {
    font-weight: 100;
}

.jobtitle-select-language,
.jobtitle-select-language-collapsed {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-top: 10px;
}

.jobtitle-authorized-languages,
.jobtitle-authorized-languages-collapsed {
    width: 283px;
    margin-left: 20px;
    height: 35px;
    font-size: 16px;
    border: 1px solid #ccc;
    box-sizing: border-box;

}

.jobtitle-func_btn {
    margin-right: 20px;
    margin-top: 20px;
    height: 35px;
    font-size: 14px;
    border: none;

}

.jobtitle-form {
    display: table;
    width: 100%;
    padding-left: 100px;
}

.jobtitle-form-collapsed {
    display: table;
    width: 100%;
    padding-left: 50px;
}

.jobtitle-input-fields div,
.jobtitle-input-fields-collapsed div {
    display: table-row;
}

.jobtitle-input-fields div label,
.jobtitle-input-fields-collapsed div label {
    display: table-cell;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;

}

.jobtitle-input-fields div input,
.jobtitle-input-fields-collapsed div input {
    display: table-cell;
    width: 500px;
    height: 35px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.jobtitle-input-fields div div,
.jobtitle-input-fields-collapsed div div {
    display: table-cell;

}

.job-title-btn-footer {
    /* margin: 0 auto; */
    /* display: flex; */
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 220px;
}

.menu {
    max-height: 200px;
    width: 500px;
    overflow-y: auto;
    white-space: nowrap;
}

.menu-item {
    white-space: normal;
    margin-right: 10px;
}

.jobtitle-success {
    background-color: #dff0d8;
    color: #3c763d;
    border: 1px solid #d6e9c6;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
}

.jobtitle-error {
    background-color: #f2dede;
    color: #a94442;
    border: 1px solid #ebccd1;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
}

.jobtitle-error-close-icon {
    cursor: pointer;
}

.jobtitle-error-close-icon:hover {
    color: #000;
}

#manual,
#bulk,
#unmapped {
    border-radius: 0px;
    z-index: 1;
}

.jobtitle-authorized-language-dropdown {
    z-index: 1;
}

.jobtitle-authorized-languages-menu {
    width: 283px;
}

@media screen and (max-width: 1500px) {
    .jobtitle-wrapper-collapsed {
        width: 1000px;
    }
}

@media screen and (max-width: 1200px) {
    .jobtitle-wrapper {
        width: 1000px;
    }
}

@media screen and (max-width: 1400) {
    .jobtitle-wrapper-collapsed {
        width: 900px;
    }
}

@media screen and (max-width: 1100px) {
    .jobtitle-wrapper {
        width: 900px;
    }
}

@media screen and (max-width: 1290px) {
    .jobtitle-wrapper-collapsed {
        width: 800px;
    }
}

@media screen and (max-width: 990px) {
    .jobtitle-wrapper {
        width: 800px;
    }

    .job-title-btn-footer {
        margin-left: 120px;
    }
}

@media screen and (max-width: 1190px) {
    .jobtitle-wrapper-collapsed {
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
    }

    .jobtitle-form-collapsed {
        display: block;
        padding-left: 0px;

    }

    .jobtitle-input-fields-collapsed div {
        display: block;
        margin-bottom: 20px;
        width: 100%;
    }

    .jobtitle-input-fields-collapsed div label {
        display: block;
        margin-bottom: 5px;
        padding: 0;
    }

    .jobtitle-input-fields-collapsed div input[type="text"] {
        display: block;
        width: 100%;
        font-size: 16px;
        border: 1px solid #ccc;
        box-sizing: border-box;
    }

    .jobtitle-input-fields-collapsed div div {
        display: block;
        width: 100%;
    }

    .menu {
        width: 100%;
    }

    .job-title-btn-footer {
        margin-left: 0px;
    }
}

@media screen and (max-width: 890px) {
    .jobtitle-wrapper {
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
    }

    .jobtitle-form {
        display: block;
        padding-left: 0px;

    }

    .jobtitle-input-fields div {
        display: block;
        margin-bottom: 20px;
        width: 100%;
    }

    .jobtitle-input-fields div label {
        display: block;
        margin-bottom: 5px;
        padding: 0;
    }

    .jobtitle-input-fields div input[type="text"] {
        display: block;
        width: 100%;
        font-size: 16px;
        border: 1px solid #ccc;
        box-sizing: border-box;
    }

    .jobtitle-input-fields div div {
        display: block;
        width: 100%;
    }

    .menu {
        width: 100%;
    }

    .job-title-btn-footer {
        margin-left: 0px;
    }
}

@media screen and (max-width: 710px) {
    .jobtitle-wrapper-collapsed {
        width: 360px;
    }
}

@media screen and (max-width: 440px) {
    .jobtitle-wrapper {
        width: 360px;
    }
}

@media screen and (max-width: 874px) {
    .jobtitle-select-language-collapsed {
        flex-direction: column;
        align-items: flex-start;
    }

    .jobtitle-authorized-languages-collapsed {
        margin-left: 0px;
    }

}

@media screen and (max-width: 574px) {
    .jobtitle-select-language {
        flex-direction: column;
        align-items: flex-start;
    }

    .jobtitle-authorized-languages {
        margin-left: 0px;
    }

}