.app-header-wrapper {
    background-color: var(--he-color-primary-600);
    height: 48px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;
}

.app-header {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    padding: 5px 10px;
}

.app-header-title {
    margin-left: 15px;
    font-size: 16px;
}

.app-header-actions {
    position: absolute;
    right: 0;
}

.app-header-actions he-button {
    margin: 0 5px;
}