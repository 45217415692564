.cardcomponent {
    height: auto !important;
    width: 300px;
    box-shadow: "1px 2px 9px grey";
    padding: 5px;
}

.cardTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 14px;
}

.cardMid {
    text-align: center;
    display: flex;
    flex-direction: row;
    overflow: auto;
    font-size: 14px;
    margin-bottom: 12px;
    word-break: break-word;
    margin-bottom: 16px;
}

.cardMidLeft {
    overflow: auto;
    margin-right: 10px;
    align-items: center;
}

.cardMidRight {
    text-align: left;
    margin-left: 5px;
}

.cardBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 16px;
}

.cardButton {
    color: #0078D4;
    text-decoration: underline;
    border-radius: 24px;
    margin-right: 8px;
    padding: 4px 12px;
    cursor: pointer;
}

.cardButton:hover {
    color: darkblue;
}
.userInitials {
    background-color: "grey";
}