.loader-wrapper {    
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-wrapper-fullpage {
    height: 100vh;
}

.loader-wrapper > div:first-child {
    text-align: center;
}

.loader-wrapper span {
    font-size: 20px;
    font-weight: 500;    
}

.spinner-loader-between {
    border: 5px solid var(--he-color-primary-100);
    border-radius: 50%;
    border-top: 5px solid var(--he-color-primary-500);
    width: 50px;
    height: 50px;
    margin: 20px auto;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

.spinner-loader{
    border: 3px solid var(--he-color-primary-100);
    border-radius: 50%;
    border-top: 3px solid var(--he-color-primary-500);
    width: 20px;
    height: 20px;
    margin-left: 3px;
    margin-right: 6px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }