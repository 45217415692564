.domain-suppression-wrapper, .domain-suppression-wrapper-collapsed {
    justify-content: center;
    padding-top: 40px;
    width: 1200px;
    margin: 0 auto;
}
.domain-suppression-header, .domain-suppression-header-collapsed {
    
    width: 100%;
    background-color: rgb(240,240,240);
    padding: 40px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.domain-suppression-heading{
    font-weight: 100;
}
.domain-suppression-authorized-countries, .domain-suppression-authorized-countries-collapsed{
    width: 283px;
    margin-left: 20px;
    height: 35px;
    font-size: 16px;
    /* border: 1px solid #ccc; */
    box-sizing: border-box;
}
.domain-suppression-create_btn, .domain-suppression-create_btn-collapsed{
    margin-left: 20px;
    height: 35px;
    width: 200px;
    font-size: 16px;
    border: none;
}

.domain-suppression-select-country, .domain-suppression-select-country-collapsed {
    display: flex;
    align-items: center;
}

.domain-suppression-manual, .domain-suppression-manual-collapsed{
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;;
}


.domain-suppression-form, .domain-suppression-form-collapsed{
    display: table;
    width: 100%;
    padding-left: 100px; 
}

.domain-suppression-input-fields div, .domain-suppression-input-fields-collapsed div {
    display: table-row;
}
.domain-suppression-input-fields div label, .domain-suppression-input-fields-collapsed div label{
    display: table-cell;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;

}
.domain-suppression-input-fields div input, .domain-suppression-input-fields-collapsed div input {
    display: table-cell;
    width: 500px;
    height: 35px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.domain-suppression-checkbox, .domain-suppression-checkbox-collapsed {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 105px;
    margin-bottom: 10px;
}
  
.domain-suppression-checkbox input[type="checkbox"], .domain-suppression-checkbox-collapsed input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.domain-suppression-checkbox label, .domain-suppression-checkbox-collapsed label {
    margin-left: 5px;
    font-size: 16px;
    font-weight: 100;
}

.domain-suppression-btn-footer, .domain-suppression-btn-footer-collapsed{
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 105px;
}

.domain-suppression-func_btn{
    margin-right: 20px;
    margin-top: 20px;
    height: 35px;
    font-size: 14px;
    border: none;

}

.domain-suppression-success{
    background-color: #dff0d8;
    color: #3c763d;
    border: 1px solid #d6e9c6;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
}

.domain-suppression-error{
    background-color: #f2dede;
    color: #a94442;
    border: 1px solid #ebccd1;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
  }
  .domain-suppression-close-icon{
    cursor: pointer;
    
  }
  .domain-suppression-close-icon:hover{
    color: #000;
  }

  .domain-suppression-authorized-countries-dropdown{
    z-index: 1;
}
.domain-suppression-authorized-countries-menu{
    width: 283px;
}

.input-field{
    display: flex;
    
}
.input-text{
    margin-right: 10px;
}
.input-file{
    background-color: transparent;
    color: transparent;
}
.domain-suppression-button{
    width: 150px;
    height: 40px;
    font-size: 16px;
    border: none;
  }

@media screen and (max-width: 1600px){
    .domain-suppression-wrapper-collapsed{
        width: 1000px;
    }
}

@media screen and (max-width: 1300px){
    .domain-suppression-wrapper{
        width: 1000px;
    }
}

@media screen and (max-width: 1400px){
    .domain-suppression-wrapper-collapsed{
        width: 950px;
    }
}

@media screen and (max-width: 1100px){
    .domain-suppression-wrapper {
        width: 950px;
      }
      
}

@media screen and (max-width: 1340px){
    .domain-suppression-wrapper-collapsed{
        padding-right: 10px;
        padding-left: 10px;
        width: 100%;
    }
}

@media screen and (max-width: 1040px){
    .domain-suppression-wrapper{
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media screen and (max-width: 714px){
    .domain-suppression-wrapper-collapsed{
        width: 350px;
    }
}

@media screen and (max-width: 460px){
    .domain-suppression-wrapper{
        width: 350px;

    }
}

@media screen and (max-width: 990px){
    .domain-suppression-select-country-collapsed{
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
    .domain-suppression-authorized-countries-collapsed{
        margin-left: 0px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 714px){
    .domain-suppression-select-country{
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
    .domain-suppression-authorized-countries{
        margin-left: 0px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1150px){
    .domain-suppression-manual-collapsed{
        flex-direction: column;
        gap: 20px;
    }
    .domain-suppression-create_btn-collapsed{
        margin-left: 0px;
    }
}

@media screen and (max-width: 970px){
    .domain-suppression-manual{
        flex-direction: column;
        gap: 20px;
    }
    .domain-suppression-create_btn{
        margin-left: 0px;
    }
}

@media screen and (max-width: 1130px) {
    .domain-suppression-form-collapsed {
        display: block;
        padding: 0px;
      }
    
      .domain-suppression-input-fields-collapsed div {
        display: block;
        margin-bottom: 20px;
      }
    
      .domain-suppression-input-fields-collapsed div label {
        display: block;
        margin-bottom: 5px;
        font-size: 16px;
        padding: 0;
      }
    
      .domain-suppression-input-fields-collapsed div input[type="text"] {
        display: block;
        width: 100%;
        height: 35px;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        box-sizing: border-box;
      }
      .domain-suppression-checkbox-collapsed{
        margin-left: 0px;
      }
      .domain-suppression-btn-footer-collapsed{
        margin-left: 0px;
      }
}

@media screen and (max-width: 910px){
    .domain-suppression-form {
        display: block;
        padding: 0px;
      }
    
      .domain-suppression-input-fields div {
        display: block;
        margin-bottom: 20px;
      }
    
      .domain-suppression-input-fields div label {
        display: block;
        margin-bottom: 5px;
        font-size: 16px;
        padding: 0;
      }
    
      .domain-suppression-input-fields div input[type="text"] {
        display: block;
        width: 100%;
        height: 35px;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        box-sizing: border-box;
      }   
      .domain-suppression-checkbox{
        margin-left: 0px;
      }
      .domain-suppression-btn-footer{
        margin-left: 0px;
      }
}

@media screen and (max-width: 790px){
    .domain-suppression-btn-footer-collapsed{
        flex-direction: column;
        justify-content: flex-start;
        width: 150px;
        /* gap: 10px; */
    }
}

@media screen and (max-width: 490px){
    .domain-suppression-btn-footer{
        flex-direction: column;
        justify-content: flex-start;
        width: 150px;
        /* gap: 10px; */
    }
}